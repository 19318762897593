import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import brandPage from "../../images/slider/K-MINE_uk.jpg";
import girlMain from "../../images/products/kai-documentooborot/docflow.png";
import pdf from "../../files/New_Документооборот_ukr.pdf";
import tehno from "../../files/technical_kai_mssql.pdf";

function ElectroDocOborotPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(200);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="ElectroDocOborotPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">ЕЛЕКТРОННИЙ ДОКУМЕНТООБІГ</h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-4 pb-5">
          <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
            <Col className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12">
              <div className="imgWrappGirl">
                <img className="imgOnPage" src={girlMain}></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-7 col-lg-7 col-md-6 col-sm-12">
              <div className="AboutPageStyleDiv">
                <h3 className="nameTextGirl">
                  Рух документів у реальному часі
                </h3>
                <span className="textAboutUsStyle">
                  Електронний Документообіг – це сучасна система електронного
                  обміну діловими документами. Забезпечує якісну цифрову
                  комунікацію установ та рух документів у реальному часі.
                </span>
                <span className="textAboutUsStyle">
                  Має україномовний інтерфейс, відповідає вимогам чинного
                  законодавства, а також інтегрована з СЕВ ОВВ.
                </span>
                <span className="textAboutUsStyle">
                  Широкий функціонал забезпечує збереження та автентичність
                  документів, авторизований доступ, протоколювання дій
                  користувачів, а також взаємодію з накладанням та перевіркою
                  кваліфікаційного електронного підпису тощо.
                </span>
                <span className="textAboutUsStyle">
                  Електронний Документообіг значно спрощує роботу організацій на
                  всіх етапах руху документів.
                </span>
                <span className="textAboutUsStyle">
                  Виключається можливість їх втрати чи псування, а також
                  забезпечується легкість та прозорість співпраці з іншими
                  організаціями.
                </span>
                <span className="textAboutUsStyle">
                  Програма має надійний ступінь технічного захисту інформації,
                  що підтверджується Експертним висновком Держспецзв’язку
                  України.
                </span>
                <div className="btnWrapDocOborot mt-3">
                  <a
                    href={pdf}
                    target="_blank"
                    className="btnGirlDocOborot me-3"
                  >
                    Завантажити презентацію
                  </a>
                  <a href={tehno} target="_blank" className="btnGirlDocOborot">
                    Технічні вимоги
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mt-4 mb-5">
        <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
          <Col className="colDocOborotStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Функціональні можливості:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">
                  Автоматизований рух документації
                </li>
                <li className="liDocOborot">Інтеграція з СЕВ ОВВ</li>
                <li className="liDocOborot">
                  Реєстрація вхідної, вихідної, внутрішньої документації
                </li>
                <li className="liDocOborot">
                  Перевірка/накладання КЕП/ЕЦП, формування штрих-кодів/QR-кодів
                </li>
                <li className="liDocOborot">
                  Інтелектуальний пошук інформації за реквізитами
                </li>
                <li className="liDocOborot">
                  Внесення резолюцій, оперативне повідомлення виконавців про
                  завдання та доручення
                </li>
                <li className="liDocOborot">
                  Гнучке налаштування прав доступу кожного користувача
                </li>
                <li className="liDocOborot">Аналіз зайнятості в підрозділах</li>
                <li className="liDocOborot">
                  Формування статистичної та аналітичної звітностей
                </li>
              </ul>
            </div>
          </Col>
          <Col className="colDocOborotStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Що Ви отримаєте:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">
                  Роботу в єдиному інформаційному просторі
                </li>
                <li className="liDocOborot">
                  Скорочення часу процесів проходження документів
                </li>
                <li className="liDocOborot">
                  Пiдвищення ефективностi роботи з документами та прозорості
                  руху
                </li>
                <li className="liDocOborot">
                  Запобігання втраті важливої інформації
                </li>
                <li className="liDocOborot">Економію часу працівників</li>
                <li className="liDocOborot">Контроль виконавчої дисципліни</li>
                <li className="liDocOborot">Економію коштів</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ElectroDocOborotPage;
