import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import brandPage from "../../images/slider/K-MINE_uk.jpg";
import girlMain from "../../images/products/kai-documentooborot/socservice.png";
import tehno from "../../files/technical_kai_mssql.pdf";

function SocialServicePage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(200);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="SocialServicePageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">ОБЛІК НАДАННЯ СОЦІАЛЬНИХ ПОСЛУГ</h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-4 pb-5">
          <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
            <Col className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12">
              <div className="imgWrappGirl">
                <img className="imgOnPage" src={girlMain}></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-7 col-lg-7 col-md-6 col-sm-12">
              <div className="AboutPageStyleDiv">
                <h3 className="nameTextGirl">
                  Єдині стандарти ділових процесів соціальної сфери
                </h3>
                <span className="textAboutUsStyle">
                  Облік надання соціальних послуг - зручний для використання
                  програмний модуль, що автоматизує роботу територіальних
                  центрів соціального обслуговування.
                </span>
                <span className="textAboutUsStyle">
                  Цифрова трансформація обліку надання соціальних послуг - це
                  насамперед вдосконалення адміністрування соціальних послуг.
                  Створення єдиного інформаційного середовища, єдиного обліку
                  надавачів та отримувачів соціальних послуг, системи
                  верифікації, а також єдиної системи управління, контролю та
                  уніфікації ділових процесів соціальної сфери.
                </span>
                <span className="textAboutUsStyle">
                  Досвід доводить, що впровадження програмного модуля “Облік
                  соціальних послуг” підвищує якість надання послуг.
                </span>
                <div className="btnWrapDocOborot mt-3">
                  {/* <a
                    // href={pdf}
                    target="_blank"
                    className="btnGirlDocOborot me-3"
                  >
                    Завантажити презентацію
                  </a> */}
                  <a
                    href={tehno}
                    target="_blank"
                    className="btnGirlDocOborot me-3"
                  >
                    Технічні вимоги
                  </a>
                  <a
                    href="https://www.youtube.com/playlist?list=PLC5VUBxdMCTmb7-yrFngq1aWYPgQ8JmPw"
                    target="_blank"
                    className="btnGirlDocOborot"
                  >
                    YouTube
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mt-4 mb-5">
        <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
          <Col className="colDocOborotStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Функціональні можливості:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">
                  Створення єдиної територіальної бази підопічних
                </li>
                <li className="liDocOborot">
                  Створення та систематизація єдиної бази видів та вартостей
                  послуг
                </li>
                <li className="liDocOborot">
                  Ведення бази даних підопічних та соціальних працівників
                </li>
                <li className="liDocOborot">Облік договорів</li>
                <li className="liDocOborot">
                  Облік робочого часу соціальних працівників
                </li>
                <li className="liDocOborot">
                  Автоматизоване формування звітності
                </li>
                <li className="liDocOborot">
                  Формування нормативно-довідкової бази даних
                </li>
                <li className="liDocOborot">
                  Режим конфіденційності шляхом розподілу прав доступу до
                  інформації
                </li>
              </ul>
            </div>
          </Col>
          <Col className="colDocOborotStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Що Ви отримаєте:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">
                  Підвищення контролю обліку робочого часу соціальних
                  працівників
                </li>
                <li className="liDocOborot">
                  Прискорення процесів обліку грошових коштів
                </li>
                <li className="liDocOborot">Вивільнення часу працівників</li>
                <li className="liDocOborot">
                  Спрощення щоденної рутинної роботи
                </li>
                <li className="liDocOborot">
                  Складання індивідуальних планів соціального супроводу
                </li>
                <li className="liDocOborot">
                  Підвищення ефективності організації надання соціальних послуг
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SocialServicePage;
