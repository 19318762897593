import React, { Component, useEffect, useState } from "react";
import {
  Button,
  Container,
  Nav,
  NavDropdown,
  Navbar,
  NavbarBrand,
} from "react-bootstrap";
import NavbarToggle from "react-bootstrap/NavbarToggle";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import logo from "../images/logo.png";
import CallBackModal from "../component/CallBackFormModal";

export default function Header() {
  // ================відслідковування активності сторінки=======================
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();

  const setActive = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    // Отримуємо поточний шлях сторінки при завантаженні
    setActive(location.pathname);
  }, [location]);

  // ===============Видимість модалки Зворотній зв'язок=============================

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="headerStyle"
        variant="dark"
      >
        <Container className="headerContainer">
          <NavbarBrand href="/" className="navbarLogoBrand d-flex">
            <img
              src={logo}
              height="50"
              width="100"
              className="d-inline-block headerLogoStyle"
              alt="Logo"
            />
            <div className="wrapperLogoText">
              <span className="logoTextValue">
                РОЗРОБНИК ПРОГРАМНОГО ЗАБЕЗПЕЧЕННЯ
              </span>
            </div>
          </NavbarBrand>
          <NavbarToggle aria-controls="responsive-navbar-nav" />
          <NavbarCollapse
            id="responsive-navbar-nav"
            className="collapseSettings"
          >
            <Nav className=" header_button">
              <div className="headerNavbutton">
                <NavDropdown
                  title="Продукти"
                  className="dropSelectMainBtn"
                  id={
                    activeLink === "/kai-documentooborot"
                      ? "ActiveProdId"
                      : "noStyleId"
                  }
                >
                  <Link
                    to="/kai-documentooborot"
                    className={
                      activeLink === "/kai-documentooborot"
                        ? "activeLinkDropHeader"
                        : "headerLink headerLinkDrop"
                    }
                  >
                    K-DOCUMENT
                  </Link>
                  <a
                    href="https://k-mine.com/ua/"
                    target="_blank"
                    className="headerLink headerLinkDrop"
                  >
                    K-MINE
                  </a>
                </NavDropdown>
                <NavDropdown
                  title="Послуги"
                  className="dropSelectMainBtn"
                  id={
                    activeLink === "/software-development"
                      ? "ActiveProdId"
                      : "noStyleId"
                  }
                >
                  <Link
                    to="/software-development"
                    className={
                      activeLink === "/software-development"
                        ? "activeLinkDropHeader"
                        : "headerLink headerLinkDrop"
                    }
                  >
                    Розробка програмного забезпечення
                  </Link>
                  <Link
                    to="/support-and-implementation"
                    className={
                      activeLink === "/support-and-implementation"
                        ? "activeLinkDropHeader"
                        : "headerLink headerLinkDrop"
                    }
                  >
                    Впровадження. Навчання. Технічна підтримка
                  </Link>
                  <Link
                    to="/creation-of-digital-models"
                    className={
                      activeLink === "/creation-of-digital-models"
                        ? "activeLinkDropHeader"
                        : "headerLink headerLinkDrop"
                    }
                  >
                    Створення цифрових моделей
                  </Link>
                  <Link
                    to="/consulting-and-geoligical-support"
                    className={
                      activeLink === "/consulting-and-geoligical-support"
                        ? "activeLinkDropHeader"
                        : "headerLink headerLinkDrop"
                    }
                  >
                    Консалтинг і геологічний супровід робіт
                  </Link>
                  <Link
                    to="/design-and-survey-works"
                    className={
                      activeLink === "/design-and-survey-works"
                        ? "activeLinkDropHeader"
                        : "headerLink headerLinkDrop"
                    }
                  >
                    Проєктно вишукувальні роботи гірничо-геологічного напрямку
                  </Link>
                  <Link
                    to="/protection-of-intellectual-ownership"
                    className={
                      activeLink === "/protection-of-intellectual-ownership"
                        ? "activeLinkDropHeader"
                        : "headerLink headerLinkDrop"
                    }
                  >
                    Охорона інтелектуальної властності
                  </Link>
                </NavDropdown>
                <Link
                  to="/partners"
                  className={
                    activeLink === "/partners"
                      ? "activeLinkNoDropHeader"
                      : "headerLink headerLinkNoDrop"
                  }
                >
                  Партнери
                </Link>
                <NavDropdown title="Заходи" className="dropSelectMainBtn">
                  <a
                    href="https://www.youtube.com/channel/UCUVBgI8vF5j1rgStMwazCuA"
                    target="_blank"
                    className="headerLink headerLinkDrop"
                  >
                    Youtube K-DOCUMENT
                  </a>
                  <a
                    href="https://www.youtube.com/playlist?list=PLHC1gqu4QcS--rl15nrdvQE4haaLm6Kpa"
                    target="_blank"
                    className="headerLink headerLinkDrop"
                  >
                    Youtube K-MINE
                  </a>
                  {/* <a
                    href="https://kai.ua/svitgis/?lang=uk"
                    target="_blank"
                    className="headerLink headerLinkDrop"
                  >
                    Міжнародний семінар "SVIT GIS-2016"
                  </a> */}
                </NavDropdown>
                {/* <a
                  href="https://blog.kai.ua/uk/"
                  target="_blank"
                  className="headerLink headerLinkNoDrop"
                >
                  Блог
                </a> */}
                <Link
                  to="/about"
                  className={
                    activeLink === "/about"
                      ? "activeLinkNoDropHeader"
                      : "headerLink headerLinkNoDrop"
                  }
                >
                  Про нас
                </Link>
                <Link
                  to="/contacts"
                  className={
                    activeLink === "/contacts"
                      ? "activeLinkNoDropHeader"
                      : "headerLink headerLinkNoDrop"
                  }
                >
                  Контакти
                </Link>
                <button className="callBackBtn" onClick={handleShow}>
                  Зворотній зв'язок
                </button>
              </div>
            </Nav>
          </NavbarCollapse>
          <CallBackModal show={show} onClose={handleClose} />
        </Container>
      </Navbar>
    </>
  );
}
