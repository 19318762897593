import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import brandPage from "../../images/slider/K-MINE_uk.jpg";

import { useState, useEffect } from "react";

function SupportPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(400);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="supportPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">
          ВПРОВАДЖЕННЯ. НАВЧАННЯ. ТЕХНІЧНА ПІДТРИМКА.
        </h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-5">
          <Row className="">
            <Col className="">
              <h5 className="softwareNameTextH5 mb-4">Впроваджуємо</h5>
              <div className="wrapperSoftwareText mb-3">
                <span className="softwareText mb-3">
                  - Встановлюємо "під ключ" програмне забезпечення K-MINE,
                  K-Document та інше програмне забезпечення власної розробки.
                </span>
              </div>
              <h5 className="softwareNameTextH5 mb-4">Навчаємо</h5>
              <div className="wrapperSoftwareText mb-3">
                <span className="softwareText mb-">
                  - Наші спеціалісти регулярно проводять навчання роботі з
                  програмним забезпеченням.
                </span>
                <span className="softwareText mb-3">
                  - Організовуються спеціальні курси як в офлайн, так і в онлайн
                  форматах, вебінари, семінари тощо.
                </span>
                <span className="softwareText mb-3">
                  - Практичні заняття проводяться на реальних прикладах роботи
                  Вашої установи та передбачають навчання практичному
                  застосуванню функціоналу програмного забезпечення.
                </span>
                <span className="softwareText mb-3">
                  - По закінченню фахівці компаній-замовників складають іспити й
                  отримують відповідні сертифікати, що підтверджують їх
                  кваліфікацію.
                </span>
              </div>
              <h5 className="softwareNameTextH5 mb-4">Інтегруємо</h5>
              <div className="wrapperSoftwareText mb-3">
                <span className="softwareText mb-3">
                  - Здійснюємо інтеграцію наших продуктів з програмними
                  продуктами інших розробників для комфортнішої роботи
                  спеціалістів Вашої компанії.
                </span>
              </div>
              <h5 className="softwareNameTextH5 mb-4">Адаптуємо</h5>
              <div className="wrapperSoftwareText mb-3">
                <span className="softwareText mb-3">
                  - Здійснюємо адаптацію та модернізацію існуючих функцій
                  відповідно до особливостей роботи Вашої компанії.
                </span>
              </div>
              <h5 className="softwareNameTextH5 mb-4">Технічна підтримка</h5>
              <div className="wrapperSoftwareText mb-5">
                <span className="softwareText mb-3">
                  - У межах технічної підтримки програмного забезпечення
                  здійснюється інформаційно-консультаційний супровід будь-якими
                  зручними для Вас засобами зв’язку 24/7.
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default SupportPage;
