import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import brandPage from "../../images/slider/K-MINE_uk.jpg";
import girlMain from "../../images/products/kai-documentooborot/cnap/1cnap.png";
import passport from "../../images/products/kai-documentooborot/cnap/foreign_passport.png";
import help from "../../images/products/kai-documentooborot/cnap/help_internally.png";
import idCard from "../../images/products/kai-documentooborot/cnap/passport_of_the_citizen.png";
import birth from "../../images/products/kai-documentooborot/cnap/birth_certificate.png";
import certificate from "../../images/products/kai-documentooborot/cnap/certificate_of_assigned_identification.png";
import cnap from "../../images/products/kai-documentooborot/cnap/2cnap.png";
// import pdf from "../../files/civiltreatment.pdf";
import tehno from "../../files/technical_kai_mssql.pdf";

function CnapPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(200);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="CnapPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">
          ЕЛЕКТРОННА СИСТЕМА ОЦІНКИ ЯКОСТІ НАДАННЯ ПОСЛУГ ДЛЯ ЦНАП
        </h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-4 pb-5">
          <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
            <Col className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12">
              <div className="imgWrappGirl">
                <img className="imgOnPage" src={girlMain}></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-7 col-lg-7 col-md-6 col-sm-12">
              <div className="AboutPageStyleDiv">
                <h3 className="nameTextGirl">Успішний ЦНАП - це просто!</h3>
                <span className="textAboutUsStyle">
                  Електронна система оцінки якості надання послуг для ЦНАП -
                  програмний модуль для реєстрації послуг Центрами надання
                  адміністративних послуг, що спрощує доступ до актуальної
                  інформації, відстеження стадій розгляду справ та отримання
                  оригіналів документів.
                </span>
                <span className="textAboutUsStyle">
                  З програмним забезпеченням легко організувати інформаційний
                  обмін та електронний документообіг при роботі з документами
                  дозвільного, погоджувального характеру між місцевими
                  дозвільними органами або іншими суб'єктами надання послуг.
                  Значна увага приділяється аналiзу ефективностi надання послуг
                  щодо скорочення процедурних дій та підвищення рівня виконавчої
                  дисципліни.
                </span>
                <span className="textAboutUsStyle">
                  Програмний модуль успішно функціонує та розширює спектр
                  адміністративних послуг для населення, а також високо оцінений
                  співробітниками ЦНАП та громадянами.
                </span>
                <div className="btnWrapDocOborot mt-3">
                  {/* <a
                    // href={pdf}
                    target="_blank"
                    className="btnGirlDocOborot me-3"
                  >
                    Завантажити презентацію
                  </a> */}
                  <a href={tehno} target="_blank" className="btnGirlDocOborot">
                    Технічні вимоги
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mt-4 mb-5">
        <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
          <Col className="colDocOborotStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Функціональні можливості:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">Автоматизована реєстрація заяв</li>
                <li className="liDocOborot">Шеринг документів</li>
                <li className="liDocOborot">
                  Інформування заявників про стан розгляду справ
                </li>
                <li className="liDocOborot">
                  Контроль виконання послуг та виконавчої дисципліни
                </li>
                <li className="liDocOborot">
                  Моніторинг роботи Центрів надання адміністративних послуг
                </li>
                <li className="liDocOborot">
                  Рейтингова оцінка якості надання послуг
                </li>
                <li className="liDocOborot">
                  Єдина база даних нормативно-довідкової інформації
                </li>
                <li className="liDocOborot">
                  Автоматизоване формування звітності
                </li>
              </ul>
            </div>
          </Col>
          <Col className="colDocOborotStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Що Ви отримаєте:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">Єдиний інформаційний центр</li>
                <li className="liDocOborot">
                  Стандартизацію та спрощення процедурних дій надання послуг
                </li>
                <li className="liDocOborot">
                  Підвищення швидкості та якості прийому заяв, видачі документів
                </li>
                <li className="liDocOborot">
                  Надання суб'єктам звернень вичерпної інформації та
                  консультацій
                </li>
                <li className="liDocOborot">
                  Поліпшення виконавчої дисципліни
                </li>
                <li className="liDocOborot">
                  Сприяння підвищенню задоволеності мешканців
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mt-4 ">
        <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
          <Col className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12">
            <div className="imgWrappGirl">
              <img className="imgOnPage" src={cnap}></img>
            </div>
          </Col>
          <Col className="col-12 col-xl-7 col-lg-7 col-md-6 col-sm-12 pt-5">
            <div className="AboutPageStyleDiv">
              <h3 className="nameTextGirl">Цифрова трансформація. Шеримо!</h3>
              <span className="textAboutUsStyle">
                Шеринг документів - новий модуль Електронної системи оцінки
                якості надання послуг, що дозволяє при оформленні
                адміністративних послуг ідентифікувати заявників за допомогою
                функції “шеринг” в смартфоні через оновлений авторизований
                мобільний додаток “Дія” 2.0.
              </span>
              <span className="textAboutUsStyle">
                Шеринг документів – це сучасний інноваційний сервіс, що дозволяє
                за допомогою QR-кодів камер смартфонів онлайн передавати,
                отримувати та перевіряти документи будь-якого заявника без їх
                паперових копій. Це значно спрощує надання та отримання
                адміністративних та інших послуг.
              </span>
              <div className="btnWrapDocOborot mt-3">
                {/* <a
                  // href={pdf}
                  target="_blank"
                  className="btnGirlDocOborot me-3"
                >
                  Завантажити презентацію
                </a> */}
                <a href={tehno} target="_blank" className="btnGirlDocOborot">
                  Технічні вимоги
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <h3 className="nameTextShering mt-5 mb-5">
          Шеринг документів дозволяє поділитися цифровими копіями документів:
        </h3>
        <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
          <Col className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-5">
            <div className="cutBg">
              <img className="imgCutBg" src={passport}></img>
              <span className="textSpanCnap ms-3">закордонний паспорт</span>
            </div>
          </Col>
          <Col className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-5">
            <div className="cutBg">
              <img className="imgCutBg" src={help}></img>
              <span className="textSpanCnap ms-3">
                довідка внутрішньо переміщеної особи (ВПО)
              </span>
            </div>
          </Col>
          <Col className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-5">
            <div className="cutBg">
              <img className="imgCutBg" src={idCard}></img>
              <span className="textSpanCnap ms-3">
                паспорт громадянина (ID-картка)
              </span>
            </div>
          </Col>
          <Col className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-5">
            <div className="cutBg">
              <img className="imgCutBg" src={birth}></img>
              <span className="textSpanCnap ms-3">
                свідоцтво про народження дитини
              </span>
            </div>
          </Col>
          <Col className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-5">
            <div className="cutBg">
              <img className="imgCutBg" src={certificate}></img>
              <span className="textSpanCnap ms-3">
                довідка про присвоєний ідентифікаційний номер платника податків
                (РНОКПП)
              </span>
            </div>
          </Col>
          <Col className="endColStyleCnap col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-5">
            <h3 className="nameTextGirl">Що Ви отримаєте:</h3>
            <ul className="ulDocOborot">
              <li className="liDocOborot">
                Нові цифрові можливості для громадян: ідентифікацію за пару
                кліків
              </li>
              <li className="liDocOborot">
                Спрощення процесу отримання послуг
              </li>
              <li className="liDocOborot">Зменшення часу реєстрації</li>
              <li className="liDocOborot">
                Цифровий пакет документів замість купи паперів
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CnapPage;
