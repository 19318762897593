import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import brandPage from "../images/slider/K-MINE_uk.jpg";
import geo from "../images/geo.png";
import phone from "../images/phone.png";
import email from "../images/email.png";
import { Form } from "react-bootstrap";
import { useSubmit } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { PIBCheck, emailCheck } from "../pattern/allPattern";
import { phoneCheck } from "../pattern/allPattern";
import { noteCheck } from "../pattern/allPattern";
import axios from "axios";
import { useState, useEffect } from "react";
import homeUrl from "../configs/homeUrl";

function ContactsHeadPage() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [servAnsw, setServAnsw] = useState("");

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(400);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================Відправка форми==================================

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      console.log("Надіслано дані", data);
      const response = await axios.post(homeUrl + `submit-form`, data);
      if (response.status === 200) {
        console.log("Надіслано", response.data);
        setServAnsw(response.data.message);
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      }
    } catch (error) {
      console.log("Помилка", error);
      setServAnsw("Помилка при відправці запиту на сервер");
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };

  return (
    <div className="ContactsPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      {/* <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container> */}
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">КОНТАКТИ</h1>
      </Container>
      <Container className="mt-5 mb-5 SoftwareFluidStyle containerContactsStyle">
        <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
          <Col className=" col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
            <Form
              onSubmit={handleSubmit(onSubmit)}
              className="form_contacts contactsForm"
            >
              <Form.Group className="mb-2" controlId="formBasicName">
                <Form.Label className="App-label">ПІБ *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ПІБ"
                  {...register("name", {
                    required: true,
                    validate: (value) => PIBCheck(value),
                  })}
                />
                {errors.name && (
                  <Form.Text className="text-danger">
                    Ім'я має містити мінімум дві літери, з першою великою і
                    рештою малих літер.
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label className="App-label">Email *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ваш email"
                  {...register("email", {
                    required: true,
                    validate: (value) => emailCheck(value),
                  })}
                />
                {errors.email && (
                  <Form.Text className="text-danger">
                    Email має бути в схожжому форматі qwerty@gmail.com
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicPhone">
                <Form.Label className="App-label">Номер телефону *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="+380 ** *** ** **"
                  {...register("phone", {
                    required: true,
                    validate: (value) => phoneCheck(value),
                  })}
                />
                {errors.phone && (
                  <Form.Text className="text-danger">
                    Телефон має бути в схожжому форматі +380980880808
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicNote">
                <Form.Label className="App-label">
                  Ваше повідомлення *
                </Form.Label>
                <Form.Control
                  as="textarea"
                  className="textArea"
                  type="text"
                  placeholder="Повідомлення"
                  {...register("note", {
                    required: true,
                    validate: (value) => noteCheck(value),
                  })}
                />
                {errors.note && (
                  <Form.Text className="text-danger">
                    З великої літери, українською мовою, довжина повідомлення не
                    більше 255 символів.
                  </Form.Text>
                )}
              </Form.Group>
              <div className="wrapperBtnSend">
                <Button type="submit" id="submit" className="btnSendForm mt-3">
                  Надіслати
                </Button>
              </div>
            </Form>
            <span className="MessageServ">{servAnsw}</span>
          </Col>
          <Col className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <h5 className="softwareNameTextH5 mb-3">Група компаній "KAI"</h5>
            <div className="wrapperSoftwareText mb-3">
              <div className="wrappIcoTextAddress">
                <img className="icoStyleAddress me-2" src={geo}></img>
                <span className="softwareText mb-2">
                  50065, м. Кривий Ріг, вул. Соборності, 32/21
                </span>
              </div>
              <div className="wrappIcoTextAddress">
                <img className="icoStyleAddress me-2" src={phone}></img>
                <span className="softwareText">+38 067 569 87 71</span>
              </div>
              <h5 className="softwareNameTextH5 mb-3 mt-4">
                Технічна підтримка "K-DOCUMENT"
              </h5>
              <div className="wrappIcoTextAddress">
                <img className="icoStyleAddress me-2" src={phone}></img>
                <span className="softwareText">+38 067 569 49 87</span>
              </div>
              <h5 className="softwareNameTextH5 mb-3 mt-4">
                Технічна підтримка "K-MINE"
              </h5>
              <div className="wrappIcoTextAddress">
                <img className="icoStyleAddress me-2" src={phone}></img>
                <span className="softwareText">+38 067 637 75 16</span>
              </div>
              <h5 className="softwareNameTextH5 mb-3 mt-4">Email</h5>
              <div className="wrappIcoTextAddress">
                <img className="icoStyleAddress me-2" src={email}></img>
                <span className="softwareText">mail@kai.ua</span>
              </div>
              <a
                className="btnGirlDocOborot texPodderzhka mt-4"
                href="https://supportkdoc.kai.ua/account/signin"
                target="_blank"
              >
                Технічна підтримка
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactsHeadPage;
