import React, { useEffect, Component, useState } from "react";
import { Button, Container, Nav, Navbar, NavbarBrand } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import logo from "../images/footer/logo-footer-onli-kai.png";
import facebook from "../images/footer/social-networking/basic/1facebook_KAI.png";
import youtube from "../images/footer/social-networking/basic/1youtube_K-MINE.png";
import iso from "../images/footer/iso.png";
import pdf from "../files/Certificate.pdf";


  export default function Footer() {

     // ================відслідковування активності сторінки=======================
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();

  const setActive = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
      // Отримуємо поточний шлях сторінки при завантаженні
      setActive(location.pathname);
  }, [location]);

    return (
      <>
        <Container fluid className="footerStyle">
          <Container className="Footer">
            <Row className="rowFooter w-100 row-cols-1 row-cols-xl-5 row-cols-lg-5 row-cols-md-2 row-cols-sm-2">
              <Col className="colStyle logoCol col-12 col-xl-2 col-lg-2 col-md-6 col-sm-6">
                <Link to="/">
                  <img
                    src={logo}
                    height="50"
                    width="100"
                    className="d-inline-block"
                    alt="Logo"
                  />
                </Link>
                <div className="wrapperLogoTextFooter">
                  <span className="logoTextValueFooter">
                    РОЗРОБНИК ПРОГРАМНОГО ЗАБЕЗПЕЧЕННЯ
                  </span>
                </div>
              </Col>
              <Col className="colStyle productCol col-12 col-xl-2 col-lg-2 col-md-6 col-sm-6 colStyle">
                <h6 className="nameFooterProduct">Продукти</h6>
                <Link to="/kai-documentooborot" className={activeLink === "/kai-documentooborot" ? "product activeLinkFooter" : "product"}>
                  K-DOCUMENT
                </Link>
                <a
                  href="https://k-mine.com/ua/"
                  target="_blank"
                  className="product"
                >
                  K-MINE
                </a>
              </Col>
              <Col className="colStyle col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6 colStyle">
                <h6 className="nameFooterProduct productMargin">Послуги</h6>
                <Link to="/software-development" className={activeLink === "/software-development" ? "service activeLinkFooter" : "service"}>
                  Розробка ПО
                </Link>
                <Link to="/support-and-implementation" className={activeLink === "/support-and-implementation" ? "service activeLinkFooter" : "service"}>
                  Впровадження. Навчання. Технічна підтримка
                </Link>
                <Link to="/creation-of-digital-models" className={activeLink === "/creation-of-digital-models" ? "service activeLinkFooter" : "service"}>
                  Створення цифрових моделей
                </Link>
                <Link
                  to="/consulting-and-geoligical-support"
                  className={activeLink === "/consulting-and-geoligical-support" ? "service activeLinkFooter" : "service"}
                >
                  Консалтинг та геологічний супровід робіт
                </Link>
                <Link to="/design-and-survey-works" className={activeLink === "/design-and-survey-works" ? "service activeLinkFooter" : "service"}>
                  Проєктно-вишукувальні роботи гірничо-геологічного напрямку
                </Link>
                <Link
                  to="/protection-of-intellectual-ownership"
                  className={activeLink === "/protection-of-intellectual-ownership" ? "service activeLinkFooter" : "service"}
                >
                  Охорона інтелектуальної власності
                </Link>
              </Col>
              <Col className="colStyle fatButtonLink col-12 col-xl-1 col-lg-1 col-md-6 col-sm-6 colStyle">
                <Link to="/partners" className={activeLink === "/partners" ? "kaiInfo activeLinkFooter" : "kaiInfo"}>
                  Партнери
                </Link>
                {/* <a
                  href="https://blog.kai.ua/uk/"
                  target="_blank"
                  className="kaiInfo"
                >
                  Блог
                </a> */}
                <Link to="/about" className={activeLink === "/about" ? "kaiInfo activeLinkFooter" : "kaiInfo"}>
                  Про нас
                </Link>
                <Link to="/contacts" className={activeLink === "/contacts" ? "kaiInfo activeLinkFooter" : "kaiInfo"}>Контакти</Link>
              </Col>
              <Col className="colStyle rightStyle col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6 colStyle">
                <div className="mailPhone">
                  <span className=" mailFooter">mail@kai.ua</span>
                  <span>+38 (067) 569-87-71</span>
                </div>
                <div className="iconSocial">
                <a href="https://www.facebook.com/k.document" target="_blank">
                  <img
                    src={facebook}
                    height="35"
                    width="35"
                    className="d-inline-block iconFacebook"
                    alt="Logo"
                  />
                  </a>
                  <a href="https://www.youtube.com/channel/UCUVBgI8vF5j1rgStMwazCuA" target="_blank">
                  <img
                    src={youtube}
                    height="35"
                    width="35"
                    className="d-inline-block iconYoutube"
                    alt="Logo"
                  />
                  </a>
                </div>
                <a className="isoLink" href={pdf} target="_blank">
                <img
                  src={iso}
                  height="40"
                  width="180"
                  className="d-inline-block iconIso"
                  alt="Logo"
                />
                </a>
              </Col>
              {/* <span className="corporation">© KAI Group, 1994-2023</span> */}
            </Row>
          </Container>
          <h5 className="testT mt-5 mb-3">© KAI Group, 1994-2023</h5>
        </Container>
      </>
    );
  }

