import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import brandPage from "../images/slider/K-MINE_uk.jpg";
import leftBox from "../images/boxes/slim_uk/k-doc-new_nobtn.png";
import rightBox from "../images/boxes/slim_uk/k-mine-new_nobtn.png";
import hand from "../images/boxes/slim/about-box-1.png";
import prise from "../images/boxes/slim/about-box-2.png";
import clock from "../images/boxes/slim/about-box-3.png";
import METINVEST from "../images/portfolio/Metinvest.jpg";
import FERREXPO from "../images/portfolio/Ferrexpo.jpg";
import UGOK from "../images/portfolio/JSC_SOUTHERN_ MINING_AND_ PROCESSING_ PLANT.jpg";
import KR from "../images/portfolio/Executive_Committee_of_the_Kryvyi_Rih_City_Council.jpg";
import Horish from "../images/portfolio/Horishnioplavnivska_City_Council.jpg";
import Rubizhne from "../images/portfolio/Rubizhne_City_Council.jpg";
import { useState, useEffect } from "react";
import CountUp from "react-countup";
import ScrollUp from "react-scroll-up";
import { Waypoint } from "react-waypoint";
import VisibilitySensor from "react-visibility-sensor";

function HomePage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================
  const [startCounter, setStartCounter] = useState(false);

  function handleEnter() {
    setStartCounter(true);
  }

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(600);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  return (
    <div className="homePageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mt-5">
        <h4 className="productsNameH4 mb-5">НАШІ ПРОДУКТИ</h4>
        <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
          <Col className="colProducts col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="leftBox d-flex">
              <img src={leftBox} className="leftBoxImg"></img>
              <Link to="/kai-documentooborot" className="linkProducts">
                Детальніше
              </Link>
            </div>
          </Col>
          <Col className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="rightBox d-flex">
              <img src={rightBox} className="rightBoxImg"></img>
              <a
                href="https://k-mine.com/ua/"
                target="_blank"
                className="linkProducts"
              >
                Детальніше
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <h4 className="productsNameH4 mb-3 mt-5">ПРО НАС</h4>
            <span className="aboutUsStyle">
              Працюємо на ринку IT, в тому числі розробки програмного
              забезпечення, з 1994 року. Наша місія - впровадження
              інтелектуального наукового потенціалу за допомогою інформаційних
              технологій власного виробництва. Незалежно від розміру та виду
              діяльності Вашої компанії, ми завжди готові запропонувати кращі
              технології.
            </span>
          </Col>
          <Col className="col-12 mb-3">
            <h4 className="productsNameH4 mb-3 mt-4">ПЕРЕВАГИ</h4>
            <span className="aboutUsStyle">
              - наявність спеціалізованих рiшень для органiв мiсцевого
              самоврядування<br></br> - адаптованість системи згідно з чинними
              вимогами законодавства<br></br> - інтуїтивно зрозумілий інтерфейс
              <br></br>- оперативна технiчна пiдтримка протягом усього періоду
              спiвпрацi<br></br> - конкурентна цiна
            </span>
          </Col>
        </Row>
        <Row className="mt-4 row-cols-1 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1">
          <Col className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-4">
            <Waypoint onEnter={handleEnter}>
              <div className="wrapperHand">
                <img src={hand} width="100"></img>
                {startCounter ? (
                  <CountUp
                    end={1245}
                    duration={4}
                    separator=","
                    redraw={false}
                    className="styleNumber"
                  />
                ) : (
                  <span className="countPartners">0</span>
                )}
                <span className="titlePartners">Клієнтів і партнерів</span>
              </div>
            </Waypoint>
          </Col>
          <Col className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-4">
            <Waypoint onEnter={handleEnter}>
              <div className="wrapperHand">
                <img src={prise} width="100"></img>
                {startCounter ? (
                  <CountUp
                    end={1567}
                    duration={6}
                    separator=","
                    redraw={false}
                    className="styleNumber"
                  />
                ) : (
                  <span className="countPartners">0</span>
                )}
                <span className="titlePartners">Реалізованих проектів</span>
              </div>
            </Waypoint>
          </Col>
          <Col className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-4">
            <Waypoint onEnter={handleEnter}>
              <div className="wrapperHand">
                <img src={clock} width="100"></img>
                {startCounter ? (
                  <CountUp
                    end={30}
                    duration={8}
                    // separator=","
                    redraw={false}
                    className="styleNumber"
                  />
                ) : (
                  <span className="countPartners">0</span>
                )}
                <span className="titlePartners">Років досвіду</span>
              </div>
            </Waypoint>
          </Col>
        </Row>
      </Container>
      <Container fluid className="bgStyleFluid mt-5">
        <Container className="mt-5 pt-3">
          {/* <VisibilitySensor onChange={handleChange}> */}
          {/* {({ isVisible }) => ( */}
          <h4 className="productsNameH4 mb-5 mt-5">НАШІ ПОСЛУГИ</h4>
          {/* //   )}
          // </VisibilitySensor> */}
          <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
            <Col className="colServiceStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="wrapperServiceValue">
                <span className="numberBoxServices">1</span>
                <span className="nameBoxService">
                  Розробка програмного забезпечення
                </span>
                <span className="textService">
                  Автоматизуємо складні процеси. Гарантуємо повний цикл розробки
                  програмного забезпечення. Впроваджуємо проєкти будь-якої
                  складності в різних галузях. Програмні комплекси дозволяють
                  надійно і швидко обробляти великі об’єми даних.
                </span>
                <Link to="/software-development" className="btnAboutNumberBox">
                  Детальніше
                </Link>
              </div>
            </Col>
            <Col className="colServiceStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="wrapperServiceValue">
                <span className="numberBoxServices">2</span>
                <span className="nameBoxService">
                  Впровадження. Навчання. Технічна підтримка
                </span>
                <span className="textService">
                  Впроваджуємо та налаштовуємо програмне забезпечення власної
                  розробки "під ключ". Навчаємо спеціалістів роботі з програмним
                  забезпеченням офлайн та онлайн. Проводимо вебінари та
                  семінари, надаємо інформаційно-консультаційну допомогу,
                  супроводження та технічну підтримку. Адаптуємо функціонал
                  програмного забезпечення під умови замовників.
                </span>
                <Link
                  to="/support-and-implementation"
                  className="btnAboutNumberBox"
                >
                  Детальніше
                </Link>
              </div>
            </Col>
            <Col className="colServiceStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="wrapperServiceValue">
                <span className="numberBoxServices">3</span>
                <span className="nameBoxService">
                  Створення цифрових моделей
                </span>
                <span className="textService">
                  Виконуємо роботи з просторового 3D моделювання родовищ
                  корисних копалин, гірничотехнічних об’єктів та гірничих
                  виробок. Цифрові моделі створюються на будь-якій стадії
                  розробки родовищ з урахуванням гірничо-геологічних умов та
                  економічних показників.
                </span>
                <Link
                  to="/creation-of-digital-models"
                  className="btnAboutNumberBox"
                >
                  Детальніше
                </Link>
              </div>
            </Col>
            <Col className="colServiceStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="wrapperServiceValue">
                <span className="numberBoxServices">4</span>
                <span className="nameBoxService">
                  Консалтинг і геологічний супровід робіт
                </span>
                <span className="textService">
                  Надаємо незалежні консалтингові послуги з проведення аудиту,
                  супроводу геологорозвідувальних робіт на основі даних
                  замовника. Допомагаємо у визначенні найбільш економічно
                  ефективного напрямку розробки родовищ корисних копалин.
                </span>
                <Link
                  to="/consulting-and-geoligical-support"
                  className="btnAboutNumberBox"
                >
                  Детальніше
                </Link>
              </div>
            </Col>
            <Col className="colServiceStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="wrapperServiceValue">
                <span className="numberBoxServices">5</span>
                <span className="nameBoxService">
                  Проєктно-вишукувальні роботи гірничо-геологічного напрямку
                </span>
                <span className="textService">
                  Виконуємо проєктування гірничих підприємств, кар’єрів та інших
                  гірничо-технічних споруд різної складності, незалежно від
                  місцезнаходження. На основі створених цифрових моделей
                  допомагаємо у виборі оптимальних технічних рішень.
                </span>
                <Link
                  to="/design-and-survey-works"
                  className="btnAboutNumberBox"
                >
                  Детальніше
                </Link>
              </div>
            </Col>
            <Col className="colServiceStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="wrapperServiceValue">
                <span className="numberBoxServices">6</span>
                <span className="nameBoxService">
                  Охорона інтелектуальної власності
                </span>
                <span className="textService">
                  Патентний повірений України допомагає з питаннями ефективної
                  правової охорони Вашої інтелектуальної власності: охорона
                  авторських і суміжних прав, торговельних марок, промислових
                  зразків, винаходів, охорона тощо.
                </span>
                <Link
                  to="/protection-of-intellectual-ownership"
                  className="btnAboutNumberBox"
                >
                  Детальніше
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mb-5">
        {/* <div className="News">
          <h4 className="productsNameH4 mb-5 mt-5">НОВИНИ</h4>
          <Link className="newsLinkStyle">Вітаємо зі святом</Link>
          <Link className="newsLinkStyle">Вітаємо зі святом</Link>
          <Link className="newsLinkStyle">Вітаємо зі святом</Link>
          <Link className="newsLinkStyle">Вітаємо зі святом</Link>
          <Link className="newsLinkStyle">Вітаємо зі святом</Link>
        </div> */}
        <div className="Partners mt-5">
          <h4 className="productsNameH4 mb-5">ПАРТНЕРИ</h4>
          <div className="wrapperPartnerIcons">
            <img src={METINVEST} width="150"></img>
            <img src={FERREXPO} width="150"></img>
            <img src={UGOK} width="150"></img>
            <img src={KR} width="150"></img>
            <img src={Horish} width="150"></img>
            <img src={Rubizhne} width="150"></img>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default HomePage;
