import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import brandPage from "../images/slider/K-MINE_uk.jpg";
import metinvest from "../images/portfolio/Metinvest.jpg";
import mineral from "../images/portfolio/MINERAL_MINING_COMPANY_LLC.jpg";
import ferrexpo from "../images/portfolio/Ferrexpo.jpg";
import dkz from "../images/portfolio/DKZ_of_Ukraine.jpg";
import ugok from "../images/portfolio/JSC_SOUTHERN_ MINING_AND_ PROCESSING_ PLANT.jpg";
import kzrk from "../images/portfolio/PJSC_Kryvbaszalizrudkom.jpg";
import arselor from "../images/portfolio/ArcelorMittal_Kryvyi_Rih.jpg";
import dsns from "../images/portfolio/State_paramilitary_ rescue_squad.jpg";
import horishnioplavnivska from "../images/portfolio/Horishnioplavnivska_City_Council.jpg";
import burshtyn from "../images/portfolio/Burshtyn_City_Council.jpg";
import melitopol from "../images/portfolio/Melitopol_City_Council.jpg";
import kr from "../images/portfolio/Executive_Committee_of_the_Kryvyi_Rih_City_Council.jpg";
import dovgRegion from "../images/portfolio/Executive_Committee_of_Dovgyntsi_District_Council_in_the_city.jpg";
import ingulRegion from "../images/portfolio/Executive_Committee_of_Ingulets_District_Council_in_the_city.jpg";
import metalRegion from "../images/portfolio/Executive_Committee_of_the_Metallurgical_District_Council_in_the_city.jpg";
import pokrovRegion from "../images/portfolio/Executive_Committee_of_the_Pokrovsky_District_Council_in_the_city.jpg";
import saksaganRegion from "../images/portfolio/Executive_Committee_of_the_Saksagan_District_Council_in_the_city.jpg";
import ternovskRegion from "../images/portfolio/Executive_Committee_of_Ternivska_District_Council_in_the_city.jpg";
import centralRegion from "../images/portfolio/Executive_Committee_of_the_Central_City_District_Council_in_the_city.jpg";
import teplodarRegion from "../images/portfolio/Teplodar_City_Council.jpg";
import malovyskivskaRegion from "../images/portfolio/Malovysk_City_Council.jpg";
import shepetivkaRegion from "../images/portfolio/Shepetivka_City_Council.jpg";
import pogrebRegion from "../images/portfolio/Pohrebyshche_City_Council.jpg";
import skvyrskaRegion from "../images/portfolio/Skvyra_City_Council.jpg";
import rubizhanskaRegion from "../images/portfolio/Rubizhne_City_Council.jpg";
import rakhivskaRegion from "../images/portfolio/Rakhiv_City_Council.jpg";
import rakhivAdminRegion from "../images/portfolio/Rakhiv_District_State_Administration.jpg";
import starosynyavskaRegion from "../images/portfolio/Starosinyavka_District_State_Administration.jpg";

function PartnersPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(200);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="PartnerPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">ПАРТНЕРИ</h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-5 pb-3">
          <Row className="row-cols-1 row-cols-xl-4 row-cols-lg-4 row-cols-md-2 row-cols-sm-1">
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={metinvest} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={mineral} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={ferrexpo} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={dkz} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={ugok} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={kzrk} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={arselor} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={dsns} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img
                  src={horishnioplavnivska}
                  className="imgPartnerBrandStyle"
                ></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={burshtyn} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={melitopol} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={kr} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={dovgRegion} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={ingulRegion} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={metalRegion} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={pokrovRegion} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img
                  src={saksaganRegion}
                  className="imgPartnerBrandStyle"
                ></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img
                  src={ternovskRegion}
                  className="imgPartnerBrandStyle"
                ></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={centralRegion} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img
                  src={teplodarRegion}
                  className="imgPartnerBrandStyle"
                ></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img
                  src={malovyskivskaRegion}
                  className="imgPartnerBrandStyle"
                ></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img
                  src={shepetivkaRegion}
                  className="imgPartnerBrandStyle"
                ></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img src={pogrebRegion} className="imgPartnerBrandStyle"></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img
                  src={skvyrskaRegion}
                  className="imgPartnerBrandStyle"
                ></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img
                  src={rubizhanskaRegion}
                  className="imgPartnerBrandStyle"
                ></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img
                  src={rakhivskaRegion}
                  className="imgPartnerBrandStyle"
                ></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img
                  src={rakhivAdminRegion}
                  className="imgPartnerBrandStyle"
                ></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="wrapperImgPartners mb-5">
                <img
                  src={starosynyavskaRegion}
                  className="imgPartnerBrandStyle"
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default PartnersPage;
