import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import brandPage from "../../images/slider/K-MINE_uk.jpg";
import girlMain from "../../images/products/kai-documentooborot/lendservice.png";
import tehno from "../../files/technical_kai_mssql.pdf";
import land from "../../files/LandKAI.pdf";

function LandResourcePage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(200);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="LandResourcePageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">
          ІНФОРМАЦІЙНА СИСТЕМА УПРАВЛІННЯ ЗЕМЕЛЬНИМИ РЕСУРСАМИ
        </h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-4 pb-5">
          <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
            <Col className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12">
              <div className="imgWrappGirl">
                <img className="imgOnPage" src={girlMain}></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-7 col-lg-7 col-md-6 col-sm-12">
              <div className="AboutPageStyleDiv">
                <h3 className="nameTextGirl">Земельні ресурси як на долоні</h3>
                <span className="textAboutUsStyle">
                  Інформаційна система управління земельними ресурсами -
                  програмний модуль для оперативного збору, накопичення, аналізу
                  інформації про земельні ділянки, а також автоматизації
                  документообігу в сфері обліку земельних ділянок.
                </span>
                <span className="textAboutUsStyle">
                  Інформаційна система управління земельними ресурсами
                  автоматизує документообіг в сфері обліку земельних ділянок,
                  об'єктів i суб'єктiв господарської дiяльностi.
                </span>
                <span className="textAboutUsStyle">
                  Дозволяє проводити аудит договорів оренди, прискорити процеси
                  створення та узгодження документiв, а також пiдвищити
                  ефективнiсть управлiння.
                </span>
                <div className="btnWrapDocOborot mt-3">
                  <a
                    href={land}
                    target="_blank"
                    className="btnGirlDocOborot me-3"
                  >
                    Завантажити презентацію
                  </a>
                  <a
                    href={tehno}
                    target="_blank"
                    className="btnGirlDocOborot me-3"
                  >
                    Технічні вимоги
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mt-4 mb-5">
        <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
          <Col className="colDocOborotStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Функціональні можливості:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">
                  Реєстрація заявок на отримання правовстановлюючих документів
                  на земельні ділянки
                </li>
                <li className="liDocOborot">
                  Ведення реєстру землекористувачів
                </li>
                <li className="liDocOborot">
                  Кількісний і якісний облік земельних ділянок за категоріями та
                  цільовим призначенням
                </li>
                <li className="liDocOborot">Облік договорів</li>
                <li className="liDocOborot">
                  Створення і ведення електронних карт територій та громад
                </li>
                <li className="liDocOborot">
                  Виконання складних запитів і швидкий пошук інформації
                </li>
                <li className="liDocOborot">
                  Ведення архівної інформації за земельними ділянками та
                  землекористувачами
                </li>
                <li className="liDocOborot">
                  Захист інформації та розподіл прав доступу
                </li>
                <li className="liDocOborot">
                  Облік правовстановлюючих документів
                </li>
              </ul>
            </div>
          </Col>
          <Col className="colDocOborotStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Що Ви отримаєте:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">
                  Підвищення ефективності управління земельними ресурсами
                </li>
                <li className="liDocOborot">
                  Прискорення узгодження правовстановлюючих документів на землю
                </li>
                <li className="liDocOborot">
                  Поліпшення якості прийняття управлінських рішень
                </li>
                <li className="liDocOborot">
                  Прискорення процесів реформування земельних відносин
                </li>
                <li className="liDocOborot">
                  Оптимізація інформаційної взаємодії учасників діловодства
                </li>
                <li className="liDocOborot">
                  Спрощення пошуку атрибутивної та картографічної інформації
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LandResourcePage;
