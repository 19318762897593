import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import brandPage from "../../images/slider/K-MINE_uk.jpg";
import workMan from "../../images/services/creation-of-digital-models.png";

import { useState, useEffect } from "react";

function CreateModelsPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(400);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="CreateModelsPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">СТВОРЕННЯ ЦИФРОВИХ МОДЕЛЕЙ</h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-5">
          <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-sm-1">
            <Col className="col-12 col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <div className="wrapperCreateModelsImgText mb-3">
                <img src={workMan} className="imgWorkManStyle"></img>
              </div>
            </Col>
            <Col className="colModelsCtyle col-12 col-xl-7 col-lg-7 col-md-12 col-sm-12">
              <div className="ulTextWrapp">
                <h3 className="ulTitleStyle">Створюємо 3D моделі:</h3>
                <ul className="ulList">
                  <li className="liRow">
                    цифрових родовищ корисних копалин різного типу
                  </li>
                  <li className="liRow">
                    поверхонь, гірничо-технічних об'єктів (кар'єри, розрізи,
                    відвали)
                  </li>
                  <li className="liRow">
                    гірничих виробок різного призначення (розвідувальні, шахтні,
                    транспортні)
                  </li>
                  <li className="liRow">
                    споруд промислового та цивільного призначення
                  </li>
                  <li className="liRow">
                    інтерактивних електронних карт генеральних планів
                    підприємств
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="colTextStyleModels">
              <span className="ModelsTextStyle mb-3 mt-5">
                3D моделі створюються на будь-якій стадії розробки родовищ або
                на будь-якому життєвому циклі компанії з урахуванням технічних,
                технологічних та економічних показників.
              </span>
              <span className="ModelsTextStyle mb-5">
                3D моделі родовищ допомагають планувати та прогнозувати їх
                розвиток, управляти ризиками, зменшувати поточні витрати та
                збільшувати прибуток компаній.
              </span>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default CreateModelsPage;
