import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import brandPage from "../../images/slider/K-MINE_uk.jpg";
import girlMain from "../../images/products/kai-documentooborot/archive.png";
import tehno from "../../files/technical_kai_mssql.pdf";
import land from "../../files/ArchiveKAI.pdf";

function ArchiveDepartmentPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(200);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="ArchiveDepartmentPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">АРХІВНИЙ ВІДДІЛ</h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-4 pb-5">
          <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
            <Col className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12">
              <div className="imgWrappGirl">
                <img className="imgOnPage" src={girlMain}></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-7 col-lg-7 col-md-6 col-sm-12">
              <div className="AboutPageStyleDiv">
                <h3 className="nameTextGirl">Центр збереження історії</h3>
                <span className="textAboutUsStyle">
                  Архівний відділ - програмний модуль для створення єдиного
                  електронного архіву документів та для автоматизації роботи з
                  документами, що передаються на архівне зберігання.
                </span>
                <span className="textAboutUsStyle">
                  З Архівним відділом Ви не будете витрачати години на пошук
                  необхідного документу в архіві. Архівний відділ з функціоналом
                  централізованого сховища різнорідних документів забезпечує
                  надійну систему збереження величезного обсягу документів з
                  чіткою структурою. Програмний продукт забезпечує швидкість та
                  надійність роботи з документами, сприяє підвищенню
                  ефективності використання інформації та повному контролю
                  архівних справ, документів тощо.
                </span>
                <div className="btnWrapDocOborot mt-3">
                  <a
                    href={land}
                    target="_blank"
                    className="btnGirlDocOborot me-3"
                  >
                    Завантажити презентацію
                  </a>
                  <a
                    href={tehno}
                    target="_blank"
                    className="btnGirlDocOborot me-3"
                  >
                    Технічні вимоги
                  </a>
                  {/* <a
                    href="https://www.youtube.com/watch?v=_IGCYEwLBeI&list=PLC5VUBxdMCTlFNwvWxbnL10lCOYDrZyrW"
                    target="_blank"
                    className="btnGirlDocOborot"
                  >
                    YouTube
                  </a> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mt-4 mb-5">
        <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
          <Col className="colDocOborotStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Функціональні можливості:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">
                  Формування каталогів, номенклатур справ
                </li>
                <li className="liDocOborot">
                  Ведення реєстру списаних документів
                </li>
                <li className="liDocOborot">Контроль термінів зберігання</li>
                <li className="liDocOborot">
                  Одночасне використання документів декількома користувачами
                </li>
                <li className="liDocOborot">
                  Видача архівних довідок, копій документів
                </li>
                <li className="liDocOborot">
                  Збереження документів до архіву за заданими параметрами
                </li>
                <li className="liDocOborot">
                  Відновлення даних з архіву відповідно до прав доступу
                </li>
                <li className="liDocOborot">Пошук за реквізитами</li>
              </ul>
            </div>
          </Col>
          <Col className="colDocOborotStyle col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Що Ви отримаєте:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">
                  Єдиний електронний архів документів
                </li>
                <li className="liDocOborot">
                  Зручний та швидкий доступ до документів
                </li>
                <li className="liDocOborot">Миттєве завантаження документів</li>
                <li className="liDocOborot">
                  Надійність і постійність, оскільки електронні документи не
                  псуються з часом
                </li>
                <li className="liDocOborot">
                  Облік ліквідованих підприємств, установ, організацій
                </li>
                <li className="liDocOborot">
                  Режим конфіденційності та захисту інформації
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ArchiveDepartmentPage;
