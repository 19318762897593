import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import brandPage from "../../images/slider/K-MINE_uk.jpg";
import girlMain from "../../images/products/kai-documentooborot/tercommunity.png";
import tehno from "../../files/tercommunity.pdf";
import land from "../../files/ArchiveKAI.pdf";

function RegisterTerCommunityPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(200);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="TerCommunityPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">РЕЄСТР ТЕРИТОРІАЛЬНИХ ГРОМАД</h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-4 pb-5">
          <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
            <Col className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12">
              <div className="imgWrappGirl">
                <img className="imgOnPage" src={girlMain}></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-7 col-lg-7 col-md-6 col-sm-12">
              <div className="AboutPageStyleDiv">
                <h3 className="nameTextGirl">
                  Основа вирішення питань територіальних громад
                </h3>
                <span className="textAboutUsStyle">
                  Реєстр територіальних громад - програмний модуль для
                  автоматизації процесів формування та ведення реєстру
                  територіальних громад.
                </span>
                <span className="textAboutUsStyle">
                  Реєстр територіальних громад актуалізує інформацію про
                  громадян, зареєстрованих на певній території, а також
                  створенню єдиного інформаційного простору.
                </span>
                <div className="btnWrapDocOborot mt-3">
                  <a
                    href={land}
                    target="_blank"
                    className="btnGirlDocOborot me-3"
                  >
                    Завантажити презентацію
                  </a>
                  <a
                    href={tehno}
                    target="_blank"
                    className="btnGirlDocOborot me-3"
                  >
                    Технічні вимоги
                  </a>
                  {/* <a
                    href="https://www.youtube.com/watch?v=_IGCYEwLBeI&list=PLC5VUBxdMCTlFNwvWxbnL10lCOYDrZyrW"
                    target="_blank"
                    className="btnGirlDocOborot"
                  >
                    YouTube
                  </a> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mt-4 mb-5">
        <Row className="row-cols-1 row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-sm-1">
          <Col className="colDocOborotStyle col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Функціональні можливості:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">
                  Створення єдиного реєстру територіальної громади
                </li>
                <li className="liDocOborot">
                  Наповнення реєстру територіальної громади актуальною
                  інформацією
                </li>
                <li className="liDocOborot">
                  Ведення бази даних фізичних осіб, які проживають/перебувають
                  на території населеного пункту
                </li>
                <li className="liDocOborot">
                  Реєстрація/зняття з реєстрації місця проживання/перебування
                  фізичних осіб
                </li>
                <li className="liDocOborot">Ведення реєстру адресних карток</li>
                <li className="liDocOborot">
                  Формування та друк за встановленими формами заяв, відмов,
                  довідок, повідомлень та звітів
                </li>
                <li className="liDocOborot">
                  Формування нормативно-довідникової бази даних
                </li>
                <li className="liDocOborot">
                  Пошук, адміністрування та захист інформації
                </li>
              </ul>
            </div>
          </Col>
          <Col className="colDocOborotStyle col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Що Ви отримаєте:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">
                  Наявність повного та актуального реєстру громадян
                </li>
                <li className="liDocOborot">
                  Видача довідок громадянам, передбачених Законом України "Про
                  територіальні громади"
                </li>
                <li className="liDocOborot">
                  Прискорення процесів надання послуг за рахунок представлення
                  інформації в електронному вигляді
                </li>
                <li className="liDocOborot">
                  Спрощення та прискорення пошуку інформації за зареєстрованими
                  особами
                </li>
                <li className="liDocOborot">
                  Автоматизацію процесів складання звітності
                </li>
                <li className="liDocOborot">
                  Підвищення ефективності та якості надання послуг
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RegisterTerCommunityPage;
