import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import brandPage from "../../images/slider/K-MINE_uk.jpg";
import comandPeople from "../../images/services/design-and-survey-works.png";

import { useState, useEffect } from "react";

function ProjectSearchPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(200);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="projectSearchPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">ПРОЄКТНО-ВИШУКУВАЛЬНІ РОБОТИ</h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-5">
          <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-sm-1">
            <Col className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="wrapperCreateModelsImgText mb-3">
                <img src={comandPeople} className="imgWorkManStyle"></img>
              </div>
            </Col>
            <Col className="pt-3 col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="ulTextWrapp">
                <h3 className="ulTitleStyle">
                  З урахуванням прогнозних макроекономічних показників для всіх
                  типів твердих корисних копалин виконуємо:
                </h3>
                <ul className="ulList">
                  <li className="liRowConsalt">
                    техніко-економічні розрахунки та обґрунтування розробки
                    родовищ (ТЕР, ТЕО)
                  </li>
                  <li className="liRowConsalt">
                    розробка проєктів та робочих проєктів експлуатації кар’єрів
                  </li>
                  <li className="liRowConsalt">
                    розробка проєктів гірничих відводів
                  </li>
                  <li className="liRowConsalt">
                    топографо-геодезичні та картографічні роботи
                  </li>
                  <li className="liRowConsalt">
                    розробка проєктів технічної та біологічної рекультивації
                  </li>
                  <li className="liRowConsalt">
                    технічний супровід проєктної документації при узгодженні та
                    проходженні державної експертизи
                  </li>
                  <li className="liRowConsalt">
                    розробка планів розвитку гірничих робіт
                  </li>
                  <li className="liRowConsalt">
                    розробка паспортів роботи гірничого обладнання
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="mt-4 pb-4">
            <Col className="colTextStyleModels">
              <span className="textStyleConsalt mb-4">
                Як результат Ви отримуєте готові рішення з раціональним
                напрямком розвитку гірничих робіт, що дозволяють підвищити
                ефективність розробки родовищ, зменшити експлуатаційні витрати
                та збільшити вартість активів.
              </span>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default ProjectSearchPage;
