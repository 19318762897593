import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import brandPage from "../../images/slider/K-MINE_uk.jpg";
import workPeople from "../../images/services/consulting-and-geoligical-support.png";

import { useState, useEffect } from "react";

function ConsaltingPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(200);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="ConsaltingPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">
          КОНСАЛТИНГ І ГЕОЛОГІЧНИЙ СУПРОВІД РОБІТ
        </h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-5">
          <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-sm-1">
            <Col className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="wrapperCreateModelsImgText mb-3">
                <img src={workPeople} className="imgWorkManStyle"></img>
              </div>
            </Col>
            <Col className="pt-3 col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="ulTextWrapp">
                <h3 className="ulTitleStyle">
                  Виконуємо роботи та надаємо послуги відповідно до міжнародних
                  стандартів за наступними напрямками:
                </h3>
                <ul className="ulList">
                  <li className="liRowConsalt">
                    аналіз наявних результатів виконаних геологорозвідувальних і
                    дослідницьких робіт на ділянках родовищ й оцінка перспективи
                    дорозвідки та приросту запасів
                  </li>
                  <li className="liRowConsalt">
                    створення баз даних за наявними результатами
                    геологорозвідувальних робіт
                  </li>
                  <li className="liRowConsalt">
                    експертиза й складання проєктів геологорозвідувальних робіт
                  </li>
                  <li className="liRowConsalt">
                    техніко-економічне обґрунтування (ТЕО) кондицій з
                    геолого-економічною оцінкою запасів і ресурсів родовищ
                  </li>
                  <li className="liRowConsalt">
                    оцінка запасів / ресурсів мінеральної сировини за
                    міжнародними стандартами (CRIRSCO, JORC, NI 43-101, PERC
                    тощо)
                  </li>
                  <li className="liRowConsalt">
                    оцінка інвестиційної привабливості родовищ
                  </li>
                  <li className="liRowConsalt">
                    надання консультаційних послуг в галузі надрокористування
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="mt-4 pb-5">
            <Col className="colTextStyleModels">
              <span className="textStyleConsalt mb-4">
                Наш багаторічний досвід дозволяє надавати широкий спектр послуг
                за різними типами родовищ твердих корисних копалин на всіх
                стадіях життєдіяльності: від розвідки до повного погашення
                запасів родовищ.
              </span>
              <span className="textStyleConsalt">
                Незалежні звіти, що базуються на точних розрахунках з
                використанням цифрових моделей родовищ, містять необхідні
                рішення для вирішення проблем надрокористувачів.
              </span>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default ConsaltingPage;
