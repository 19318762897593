import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import brandPage from "../../images/slider/K-MINE_uk.jpg";
import hands from "../../images/services/soft-dev-reliability.png";
import handGive from "../../images/services/soft-dev-practicality.png";
import fine from "../../images/services/soft-dev-efficiency.png";
import mobile from "../../images/services/soft-dev-mobility.png";
import pazle from "../../images/services/soft-dev-functionality.png";

import { useState, useEffect } from "react";

function SoftwareDevelopmentPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(400);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="softwarePageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      {/* ==================якщо додати клас .typewriter до h1 то буде стиль печатної машинки==== */}
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">
          РОЗРОБКА ПРОГРАМНОГО ЗАБЕЗПЕЧЕННЯ
        </h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1 pb-5">
        <Container className="mt-5">
          <h5 className="softwareH5 mb-5">
            Виконуємо повний цикл робіт розробки програмного забезпечення “під
            ключ” в різноманітних галузях.<br></br> Автоматизуємо інтегровані
            процеси, надаючи Вам можливість максимально зекономити час і кошти.
          </h5>
          <Row className="">
            <Col className="">
              <h5 className="softwareNameTextH5 mb-4">Що ми робимо?</h5>
              <div className="wrapperSoftwareText">
                <span className="softwareText mb-3">
                  - Розробляємо програмне забезпечення та втілюємо Ваші ідеї
                  будь-якої складності, надаємо інформаційно-консультаційну
                  підтримку після впровадження продукту.
                </span>
                <span className="softwareText mb-3">
                  - Маємо понад декілька тисяч реалізованих проєктів і переданих
                  ліцензій.
                </span>
                <span className="softwareText mb-3">
                  - За час своєї роботи створили інноваційне програмне
                  забезпечення K-MINE для автоматизації гірничодобувних
                  підприємств та K-Document для автоматизації документообігу та
                  вирішення важливих питань організацій різних розмірів та форм
                  власності.
                </span>
              </div>
              <h5 className="softwareNameTextH5 mb-4 mt-3">Як ми це робимо?</h5>
              <div className="wrapperSoftwareText">
                <span className="softwareText mb-3">
                  - Детально вивчаємо інформаційні потоки та специфіку Вашого
                  бізнесу, адаптуємо Ваші ідеї, допомагаємо їх реалізації,
                  розробляємо концепції, алгоритми та випускаємо готовий
                  програмний продукт.
                </span>
                <span className="softwareText mb-3">
                  - Ви завжди в курсі, на якій стадії розробки знаходиться Ваш
                  проєкт. Знаєте, коли отримаєте результат, куди втілено
                  найкращий багаторічний досвід наших спеціалістів.
                </span>
              </div>
              <h5 className="softwareNameTextH5 mb-4 mt-3">
                Безпека та тестування
              </h5>
              <div className="wrapperSoftwareText">
                <span className="softwareText mb-3">
                  Програмне забезпечення тестується для забезпечення його:
                </span>
              </div>
            </Col>
          </Row>
          <Row className="row-cols-1 row-cols-xl-7 row-cols-lg-7 row-cols-md-2 row-cols-sm-1 mt-5">
            <Col className="col-1 col-xl-1 col-lg-1 col-md-3 col-sm-0"></Col>
            <Col className="colStyleSoftware col-12 col-xl-2 col-lg-2 col-md-6 col-sm-12">
              <div className="softwareImgText">
                <img src={hands} className="SecurityStyleImage"></img>
                <span className="textImgSecurity">надійності</span>
              </div>
            </Col>
            <Col className="colStyleSoftware col-12 col-xl-2 col-lg-2 col-md-6 col-sm-12">
              <div className="softwareImgText">
                <img src={handGive} className="SecurityStyleImage"></img>
                <span className="textImgSecurity">практичності</span>
              </div>
            </Col>
            <Col className="colStyleSoftware col-12 col-xl-2 col-lg-2 col-md-6 col-sm-12">
              <div className="softwareImgText">
                <img src={fine} className="SecurityStyleImage"></img>
                <span className="textImgSecurity">ефективності</span>
              </div>
            </Col>
            <Col className="colStyleSoftware col-12 col-xl-2 col-lg-2 col-md-6 col-sm-12">
              <div className="softwareImgText">
                <img src={mobile} className="SecurityStyleImage"></img>
                <span className="textImgSecurity">мобільності</span>
              </div>
            </Col>
            <Col className="colStyleSoftware col-12 col-xl-2 col-lg-2 col-md-6 col-sm-12">
              <div className="softwareImgText">
                <img src={pazle} className="SecurityStyleImage"></img>
                <span className="textImgSecurity">функціональності</span>
              </div>
            </Col>
            <Col className="col-1 col-xl-1 col-lg-1 col-md-0 col-sm-0"></Col>
          </Row>
        </Container>
        <h5 className="softwareH5 mt-5">
          Гарантуємо надійний ступінь технічного захисту інформації.<br></br>
          Довіртеся експертам і отримайте суттєві вигоди.
        </h5>
      </Container>
    </div>
  );
}

export default SoftwareDevelopmentPage;
