import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import brandPage from "../../images/slider/K-MINE_uk.jpg";
import ElectroDocument from "../../images/products/kai-documentooborot/Electronic_Document_Management.png";
import ElectroInfoSyst from "../../images/products/kai-documentooborot/Land_management_information_system.png";
import ElectroPeople from "../../images/products/kai-documentooborot/Citizens_appeals_WEB.png";
import Kadry from "../../images/products/kai-documentooborot/Personnel_accounting.png";
import ElectroSystem from "../../images/products/kai-documentooborot/Electronic_system_for_assessing_quality_services.png";
import ElectroArhive from "../../images/products/kai-documentooborot/Archival_department.png";
import SocialData from "../../images/products/kai-documentooborot/Accounting_for_the_provision-of_social_services.png";
import Register from "../../images/products/kai-documentooborot/Register_of_territorial_communities.png";

import { useState, useEffect } from "react";

function KdocumentPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(400);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  return (
    <div className="kDocumentPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mt-5">
        <h4 className="productsNameH4 mb-5">НАШІ ПРОДУКТИ</h4>
        <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-sm-1">
          <Col className="colProductPage col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="leftBox prodBoxKdoc">
              <img src={ElectroDocument} className="leftElectroImg"></img>
              <div className="aboutElectroOb">
                <Link to="/about-us-docflow" className="linkDocProduct">
                  Електронний Документообіг
                </Link>
                <span className="textElectroOb">
                  Cистема електронного документообігу для автоматизації
                  діловодства в організаціях різних напрямків діяльності та форм
                  власності.
                </span>
              </div>
            </div>
          </Col>
          <Col className="colProductPage col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="leftBox prodBoxKdoc">
              <img src={ElectroInfoSyst} className="leftElectroImg"></img>
              <div className="aboutElectroOb">
                <Link to="/about-us-landservice" className="linkDocProduct">
                  Інформаційна система управління земельними ресурсами
                </Link>
                <span className="textElectroOb">
                  Модуль для оперативного збору, накопичення, аналізу інформації
                  про об’єкти інженерної інфраструктури населеного пункту, а
                  також автоматизації документообігу в сфері обліку земельних
                  ділянок.
                </span>
              </div>
            </div>
          </Col>
          <Col className="colProductPage col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="leftBox prodBoxKdoc">
              <img src={ElectroPeople} className="leftElectroImg"></img>
              <div className="aboutElectroOb">
                <Link to="/about-us-civiltreatment" className="linkDocProduct">
                  Звернення громадян + WEB. Звернення
                </Link>
                <span className="textElectroOb">
                  Модуль для автоматизації роботи зі зверненнями, що подаються
                  як особисто, так і з використанням мережі Інтернет або іншими
                  засобами електронного зв'язку.
                </span>
              </div>
            </div>
          </Col>
          <Col className="colProductPage col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="leftBox prodBoxKdoc">
              <img src={Kadry} className="leftElectroImg"></img>
              <div className="aboutElectroOb">
                <Link to="/about-us-personnelquestion" className="linkDocProduct">
                  Кадровий облік
                </Link>
                <span className="textElectroOb">
                  Модуль для автоматизації обліку та управління персоналом на
                  підприємствах будь-якого профілю діяльності та форм власності.
                </span>
              </div>
            </div>
          </Col>
          <Col className="colProductPage col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="leftBox prodBoxKdoc">
              <img src={ElectroSystem} className="leftElectroImg"></img>
              <div className="aboutElectroOb">
                <Link to="/about-us-cnap" className="linkDocProduct">
                  Електронна система оцінки якості надання послуг для ЦНАП
                </Link>
                <span className="textElectroOb">
                  Модуль для реєстрації та надання послуг в електронному
                  вигляді, відстеження ходу розгляду справ, отримання копій
                  дозвільних документів, доступу до актуальної інформації.
                </span>
              </div>
            </div>
          </Col>
          <Col className="colProductPage col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="leftBox prodBoxKdoc">
              <img src={ElectroArhive} className="leftElectroImg"></img>
              <div className="aboutElectroOb">
                <Link to="/about-us-archivedepartment" className="linkDocProduct">
                  Архівний відділ
                </Link>
                <span className="textElectroOb">
                  Модуль для автоматизації роботи з документами, що передаються
                  на архівне зберігання та створення єдиного електронного архіву
                  документів.
                </span>
              </div>
            </div>
          </Col>
          <Col className="colProductPage col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="leftBox prodBoxKdoc">
              <img src={SocialData} className="leftElectroImg"></img>
              <div className="aboutElectroOb">
                <Link to="/about-us-socialservice" className="linkDocProduct">
                  Облік надання соціальних послуг
                </Link>
                <span className="textElectroOb">
                  Модуль для автоматизації роботи територіальних центрів
                  соціального обслуговування.
                </span>
              </div>
            </div>
          </Col>
          <Col className="colProductPage col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="leftBox prodBoxKdoc">
              <img src={Register} className="leftElectroImg"></img>
              <div className="aboutElectroOb">
                <Link to="/about-us-regist-ter-commun" className="linkDocProduct">
                  Реєстр територіальних громад
                </Link>
                <span className="textElectroOb">
                  Модуль для автоматизації процесів формування та ведення
                  реєстру територіальної громади.
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default KdocumentPage;
