// import React from "react";
import React, { Component, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, ModalFooter } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useSubmit } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { PIBCheck } from "../pattern/allPattern";
import { emailCheck } from "../pattern/allPattern";
import { phoneCheck } from "../pattern/allPattern";
import { noteCheck } from "../pattern/allPattern";
import { Modal } from "react-bootstrap";
import axios from "axios";
import homeUrl from "../configs/homeUrl";

function CallBackModal(props) {
  const [servAnsw, setServAnsw] = useState("");
  // ============================Відправка форми==================================

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      console.log("Надіслано дані", data);
      const response = await axios.post(homeUrl + `submit-form`, data);
      if (response.status === 200) {
        console.log("Надіслано", response.data);
        setServAnsw(response.data.message);
        setTimeout(() => {
          window.location.reload();
          props.onClose();
        }, 1500);
      }
    } catch (error) {
      console.log("Помилка", error);
      setServAnsw("Помилка при відправці запиту на сервер");
      setTimeout(() => {
        // window.location.reload();
        // props.onClose();
      }, 5000);
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onClose}
        size="lg"
        centered
        backdrop="static"
        backdropClassName="modal-backdrop"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Container className="SoftwareFluidStyle containerModalStyle">
            <Row className="">
              <Col className="">
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  className="form_contacts"
                >
                  <Form.Group className="mb-2" controlId="formBasicName">
                    <Form.Label className="App-label">ПІБ *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ПІБ"
                      {...register("name", {
                        required: true,
                        validate: (value) => PIBCheck(value),
                      })}
                    />
                    {errors.name && (
                      <Form.Text className="text-danger">
                        Ім'я має містити мінімум дві літери, з першою великою і
                        рештою малих літер, кожне слово з великої літери.
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Label className="App-label">Email *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ваш email"
                      {...register("email", {
                        required: true,
                        validate: (value) => emailCheck(value),
                      })}
                    />
                    {errors.email && (
                      <Form.Text className="text-danger">
                        Email має бути в схожжому форматі qwerty@gmail.com
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="formBasicPhone">
                    <Form.Label className="App-label">
                      Номер телефону *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="+380 ** *** ** **"
                      {...register("phone", {
                        required: true,
                        validate: (value) => phoneCheck(value),
                      })}
                    />
                    {errors.phone && (
                      <Form.Text className="text-danger">
                        Телефон має бути в схожжому форматі +380980880808
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="formBasicNote">
                    <Form.Label className="App-label">
                      Ваше повідомлення *
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      className="textArea"
                      type="text"
                      placeholder="Повідомлення"
                      {...register("note", {
                        required: true,
                        validate: (value) => noteCheck(value),
                      })}
                    />
                    {errors.note && (
                      <Form.Text className="text-danger">
                        З великої літери, українською мовою, довжина
                        повідомлення не більше 255 символів.
                      </Form.Text>
                    )}
                  </Form.Group>
                  <div className="wrapperBtnSend CallBackWrapperBtn2 mt-3">
                    <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 d-flex w-100">
                      <Col className="modalColCenter col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2">
                        <Button
                          type="submit"
                          id="submit"
                          className="btnSendForm btnSendModal"
                        >
                          Надіслати
                        </Button>
                      </Col>
                      <Col className="modalColCenter col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2">
                        <a
                          className="btnGirlDocOborot callBackTehPodd"
                          href="https://supportkdoc.kai.ua/account/signin"
                          target="_blank"
                        >
                          Технічна підтримка
                        </a>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <span className="answeMessageServ">{servAnsw}</span>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CallBackModal;
