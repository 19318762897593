import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import brandPage from "../../images/slider/K-MINE_uk.jpg";
import closeTV from "../../images/services/protection-of-intellectual-ownership.png";

import { useState, useEffect } from "react";

function SecurityIntelectPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(200);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="securityIntelectPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">ОХОРОНА ІНТЕЛЕКТУАЛЬНОЇ ВЛАСНОСТІ</h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-5 pb-5">
          <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-sm-1">
            <Col className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="wrapperCreateModelsImgText mb-3">
                <img src={closeTV} className="imgWorkManStyle"></img>
              </div>
            </Col>
            <Col className="pt-3 col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="ulTextWrapp">
                {/* <h3 className="ulTitleStyle">
                  З урахуванням прогнозних макроекономічних показників для всіх
                  типів твердих корисних копалин виконуємо:
                </h3> */}
                <ul className="ulListSecurity">
                  <li className="liRowSecurity">
                    Консультації з питань ефективної правової охорони Вашої
                    інтелектуальної власності патентного повіреного України
                    (реєстраційний номер 238), кандидата технічних наук
                  </li>
                  <li className="liRowSecurityName">
                    Назаренко Наталії Володимирівни
                  </li>
                  <li className="liRowSecurityEmail">email: nvn@kai.ua</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row className="mt-4 pb-4">
          <Col className="colTextSecurity">
            <div className="ulTextWrapp">
              <h3 className="ulTitleStyle">Охорона торгівельних марок</h3>
              <ul className="ulList">
                <li className="liRowConsalt">
                  юридичний супровід реєстрації “під ключ” від подання заявки до
                  отримання Свідоцтва на знак для товарів і послуг
                </li>
                <li className="liRowConsalt">
                  взаємодія з Укрпатентом online через електронний документообіг
                </li>
                <li className="liRowConsalt">
                  юридичний супровід продовження терміну дії торговельних марок
                </li>
                <li className="liRowConsalt">
                  підготовка документів для реєстрації ліцензійних договорів і
                  договорів на передачу права власності
                </li>
              </ul>
              <h3 className="ulTitleStyle">Охорона програмного забезпечення</h3>
              <ul className="ulList">
                <li className="liRowConsalt">
                  юридичний супровід реєстрації “під ключ” від подання заявки до
                  отримання Свідоцтва авторських прав на програмне забезпечення,
                  бази даних та відповідних і договорів про передачу прав
                </li>
                <li className="liRowConsalt">
                  внесення до Державного реєстру виробників і розповсюджувачів
                  програмного забезпечення
                </li>
                <li className="liRowConsalt">
                  складання авторських договорів з програмістами та юридичними
                  особами-розробниками програмного забезпечення
                </li>
                <li className="liRowConsalt">
                  юридичний супровід реєстрації промислових зразків з метою
                  охорони шрифтів і екранних форм
                </li>
                <li className="liRowConsalt">
                  супровід реєстрації торговельних марок, під якими програмне
                  забезпечення поширюється на ринку
                </li>
                <li className="liRowConsalt">
                  реєстрація ідей і алгоритмів програмного забезпечення як
                  винаходів і корисних моделей
                </li>
              </ul>
              <h3 className="ulTitleStyle">
                Охорона промислових зразків (дизайнерських розробок)
              </h3>
              <ul className="ulList">
                <li className="liRowConsalt">
                  юридичний супровід реєстрації “під ключ” від подання заявки до
                  отримання патенту на промислові зразки
                </li>
                <li className="liRowConsalt">
                  взаємодія з Укрпатентом online через електронний документообіг
                </li>
                <li className="liRowConsalt">підтримка дії патентів</li>
                <li className="liRowConsalt">
                  підготовка документів для реєстрації ліцензійних договорів і
                  договорів передачі права власності
                </li>
                <li className="liRowConsalt">
                  розробка стратегії подальшої охорони об'єктів промислового
                  дизайну
                </li>
              </ul>
              <h3 className="ulTitleStyle">
                Охорона авторських і суміжних прав
              </h3>
              <ul className="ulList">
                <li className="liRowConsalt">
                  юридичний супровід реєстрації “під ключ” від подання заявки до
                  отримання Свідоцтва авторських прав на програмне забезпечення,
                  бази даних
                </li>
                <li className="liRowConsalt">
                  супровід реєстрації договорів про передачу прав
                </li>
              </ul>
              <h3 className="ulTitleStyle">
                Охорона винаходів (корисних моделей)
              </h3>
              <ul className="ulList">
                <li className="liRowConsalt">
                  юридичний супровід реєстрації “під ключ” від подання заявки до
                  отримання патентів на винаходи і корисні моделі
                </li>
                <li className="liRowConsalt">
                  взаємодія з Укрпатентом online через електронний документообіг
                </li>
                <li className="liRowConsalt">
                  супровід підтримки дії патентів
                </li>
                <li className="liRowConsalt">
                  підготовка документів для реєстрації ліцензійних договорів і
                  договорів передачі права власності
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SecurityIntelectPage;
