import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import brandPage from "../../images/slider/K-MINE_uk.jpg";
import girlMain from "../../images/products/kai-documentooborot/clerks.png";
import tehno from "../../files/technical_kai_mssql.pdf";
import land from "../../files/uk_clerks_08_02_21.pdf";

function PersonnelQuestionPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(200);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="PersonnelQuestionPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">КАДРОВИЙ ОБЛІК</h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-4 pb-5">
          <Row className="row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
            <Col className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12">
              <div className="imgWrappGirl">
                <img className="imgOnPage" src={girlMain}></img>
              </div>
            </Col>
            <Col className="col-12 col-xl-7 col-lg-7 col-md-6 col-sm-12">
              <div className="AboutPageStyleDiv">
                <h3 className="nameTextGirl">
                  Ваш експерт в кадрових питаннях
                </h3>
                <span className="textAboutUsStyle">
                  Кадровий облік - модуль для автоматизації обліку та управління
                  персоналом на підприємствах будь-якого профілю діяльності та
                  форм власності.
                </span>
                <span className="textAboutUsStyle">
                  Кадровий облік - це сучасний програмний модуль, що забезпечує
                  ефективне та зручне виконання кадрового діловодства відповідно
                  до чинного законодавства. Допомагає суттєво скоротити час на
                  обробку інформації та оформлення документів.
                </span>
                <span className="textAboutUsStyle">
                  Досвід доводить ефективність впровадження модуля “Кадровий
                  облік” для підприємств із будь- якою чисельністю працівників.
                </span>
                <div className="btnWrapDocOborot mt-3">
                  <a
                    href={land}
                    target="_blank"
                    className="btnGirlDocOborot me-3"
                  >
                    Завантажити презентацію
                  </a>
                  <a
                    href={tehno}
                    target="_blank"
                    className="btnGirlDocOborot me-3"
                  >
                    Технічні вимоги
                  </a>
                  <a
                    href="https://www.youtube.com/watch?v=_IGCYEwLBeI&list=PLC5VUBxdMCTlFNwvWxbnL10lCOYDrZyrW"
                    target="_blank"
                    className="btnGirlDocOborot"
                  >
                    YouTube
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mt-4 mb-5">
        <Row className="row-cols-1 row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-sm-1">
          <Col className="colDocOborotStyle col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Функціональні можливості:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">
                  Автоматичне формування документації та стандартизованих
                  звітів: штатний розклад, вакантні посади тощо
                </li>
                <li className="liDocOborot">
                  Ведення персоніфікованого обліку працівників за категоріями,
                  професіями, відділами та іншими реквізитами
                </li>
                <li className="liDocOborot">
                  Пошук та вибірка за реквізитами (табельний номер, паспортні
                  дані, рік народження, дата прийому на роботу, підрозділи тощо)
                  з можливістю групування даних
                </li>
                <li className="liDocOborot">
                  Формування табелю обліку використання робочого часу з
                  можливістю коригування
                </li>
                <li className="liDocOborot">
                  Формування статистичних та інших звітів
                </li>
                <li className="liDocOborot">
                  Ведення електронного архіву даних персоналу
                </li>
                <li className="liDocOborot">
                  Можливість роботи з декількома базами даних
                </li>
                <li className="liDocOborot">
                  Авторизація, ідентифікація та розмежування повноважень
                  користувачів
                </li>
              </ul>
            </div>
          </Col>
          <Col className="colDocOborotStyle col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="textWrapDocOborotBottom">
              <h3 className="nameTextGirl">Що Ви отримаєте:</h3>
              <ul className="ulDocOborot">
                <li className="liDocOborot">
                  Скорочення часу на підготовку кадрових документів
                </li>
                <li className="liDocOborot">
                  Узгоджену роботу суб’єктів управління персоналом
                </li>
                <li className="liDocOborot">
                  Побудова процесів кадрового діловодства Вашої установи
                </li>
                <li className="liDocOborot">
                  Електронний класифікатор професій
                </li>
                <li className="liDocOborot">
                  Оформлення розпорядчих кадрових документів
                </li>
                <li className="liDocOborot">
                  Своєчасну актуалізацію документації
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PersonnelQuestionPage;
