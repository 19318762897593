import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import brandPage from "../images/slider/K-MINE_uk.jpg";
import { Waypoint } from "react-waypoint";
import CountUp from "react-countup";
import hand from "../images/boxes/slim/about-box-1.png";
import prise from "../images/boxes/slim/about-box-2.png";
import clock from "../images/boxes/slim/about-box-3.png";

function AboutUsPage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Після завантаження сторінки встановлюємо imageLoaded в true
    setImageLoaded(true);
    scrollToTop();
  }, []);

  // ============================Scroll page Count==================================
  const [startCounter, setStartCounter] = useState(false);

  function handleEnter() {
    setStartCounter(true);
  }

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(200);

  useEffect(() => {
    // Додаємо слухача для події прокрутки
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Прибираємо слухача після виходу з компонента
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    // Якщо користувач прокручує вниз, встановлюємо isVisible в true
    // Якщо користувач прокручує вгору, встановлюємо isVisible в false
    setIsVisible(isScrollingDown);
    setPrevScrollPos(currentScrollPos);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Анімація прокручування до верху
  };

  // ============================AnimatedText==================================

  return (
    <div className="AboutUsPageDiv">
      {isVisible ? (
        <span className="upArrow" onClick={scrollToTop}>
          ^
        </span>
      ) : null}
      <Container fluid className="homeFluid">
        <img
          src={brandPage}
          className={`brandpage ${imageLoaded ? "show" : ""}`}
        ></img>
      </Container>
      <Container className="mb-5">
        <h1 className="SoftwareName mt-5">ПРО НАС</h1>
      </Container>
      <Container fluid className="SoftwareFluidStyle pt-1">
        <Container className="mt-4 pb-3">
          <Row className="">
            <Col className="">
              <div className="AboutPageStyleDiv">
                <h3 className="nameText">
                  Наша місія - впровадження інтелектуального наукового
                  потенціалу<br></br> за допомогою інформаційних технологій
                  власного виробництва
                </h3>
                <span className="textAboutUsStyle">
                  Понад 25 років у сфері ІТ технологій розробляємо та успішно
                  впроваджуємо “під ключ” новітні рішення для муніципальних
                  установ, органів виконавчої влади та місцевого самоврядування.
                </span>
                <span className="textAboutUsStyle">
                  Протягом багатьох років програмні модулі K-Document успішно
                  використовуються в державних інституціях, наприклад, у
                  Виконавчих комітетах Криворізької та Горішньоплавнівської
                  міських радах, Державних воєнізованих гірничорятувальних
                  загонах МНС України, в інших виконавчих комітетах, районних та
                  селищних радах.
                </span>
                <span className="textAboutUsStyle">
                  Нині K-Document розвивається у напрямку комплексної
                  автоматизації документообігу підприємств державної та інших
                  форм власності, а також підприємств житлово-комунального
                  господарства.
                </span>
                <span className="textAboutUsStyle">
                  Використання програмних модулів K-Document, що легко
                  інтегруються між собою, дозволяє створити єдиний інформаційний
                  простір організації, запровадити єдині стандарти електронного
                  документообігу та економити близько 20-30% робочого часу
                  спеціалістів в залежності від розмірів організації.
                </span>
                <span className="textAboutUsStyle">
                  K-Document - рух Ваших документів, контрольований в режимі
                  реального часу. Ми забезпечуємо максимальну віддачу від Ваших
                  інвестицій в IT, а Ви зможете зосередитися на основній роботі.
                </span>
                <span className="textAboutUsStyle">
                  Крім того, ми розробляємо програмні рішення для
                  гірничодобувної галузі. Десятки років гірничодобувні
                  підприємства на території України, в країнах Центральної
                  Європи та Азії використовують K-MINE та отримують суттєвий
                  економічний ефект.
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mt-5 row-cols-1 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1">
            <Col className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-4">
              <Waypoint onEnter={handleEnter}>
                <div className="wrapperHand">
                  <img src={hand} width="100"></img>
                  {startCounter ? (
                    <CountUp
                      end={1245}
                      duration={4}
                      separator=","
                      redraw={false}
                      className="styleNumber"
                    />
                  ) : (
                    <span className="countPartners">0</span>
                  )}
                  <span className="titlePartners">Клієнтів і партнерів</span>
                </div>
              </Waypoint>
            </Col>
            <Col className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-4">
              <Waypoint onEnter={handleEnter}>
                <div className="wrapperHand">
                  <img src={prise} width="100"></img>
                  {startCounter ? (
                    <CountUp
                      end={1567}
                      duration={6}
                      separator=","
                      redraw={false}
                      className="styleNumber"
                    />
                  ) : (
                    <span className="countPartners">0</span>
                  )}
                  <span className="titlePartners">Реалізованих проектів</span>
                </div>
              </Waypoint>
            </Col>
            <Col className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-4">
              <Waypoint onEnter={handleEnter}>
                <div className="wrapperHand">
                  <img src={clock} width="100"></img>
                  {startCounter ? (
                    <CountUp
                      end={30}
                      duration={8}
                      // separator=","
                      redraw={false}
                      className="styleNumber"
                    />
                  ) : (
                    <span className="countPartners">0</span>
                  )}
                  <span className="titlePartners">Років досвіду</span>
                </div>
              </Waypoint>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mt-4 mb-5">
        <div className="AboutPageStyleDiv">
          <h3 className="nameTextLink">Продукти K-Document:</h3>
          <Link to="/about-us-docflow" className="LinkStyleAboutUs">
            Електронний Документообіг
          </Link>
          <Link to="/about-us-civiltreatment" className="LinkStyleAboutUs">
            Звернення громадян + WEB. Звернення
          </Link>
          <Link to="/about-us-cnap" className="LinkStyleAboutUs">
            Електронна система оцінки якості надання послуг для ЦНАП
          </Link>
          <Link to="/about-us-socialservice" className="LinkStyleAboutUs">
            Облік надання соціальних послуг
          </Link>
          <Link to="/about-us-landservice" className="LinkStyleAboutUs">
            Інформаційна система управління земельними ресурсами
          </Link>
          <Link to="/about-us-personnelquestion" className="LinkStyleAboutUs">
            Кадровий облік
          </Link>
          <Link to="/about-us-archivedepartment" className="LinkStyleAboutUs">
            Архівний відділ
          </Link>
          <Link to="/about-us-regist-ter-commun" className="LinkStyleAboutUs">
            Реєстр територіальних громад
          </Link>
        </div>
        <div className="AboutPageStyleDiv mt-5">
          <h3 className="nameTextLink">Наші послуги:</h3>
          <Link to="/software-development" className="LinkStyleAboutUs">
            Розробка програмного забезпечення
          </Link>
          <Link to="/support-and-implementation" className="LinkStyleAboutUs">
            Впровадження. Навчання. Технічна підтримка
          </Link>
          <Link to="/creation-of-digital-models" className="LinkStyleAboutUs">
            Створення цифрових моделей
          </Link>
          <Link
            to="/consulting-and-geoligical-support"
            className="LinkStyleAboutUs"
          >
            Консалтинг і геологічний супровід робіт
          </Link>
          <Link to="/design-and-survey-works" className="LinkStyleAboutUs">
            Проєктно-вишукувальні роботи гірничо-геологічного напрямку
          </Link>
          <Link
            to="/protection-of-intellectual-ownership"
            className="LinkStyleAboutUs"
          >
            Охорона інтелектуальної власності
          </Link>
          <a
            href="https://k-mine.com/ua/"
            target="_blank"
            className="LinkStyleAboutUs"
          >
            K-MINE
          </a>
        </div>
      </Container>
    </div>
  );
}

export default AboutUsPage;
