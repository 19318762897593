import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import KdocumentPage from "./pages/products/KdocumentPage";
import SoftwareDevelopmentPage from "./pages/services/SoftwareDevelopmentPage";
import SupportPage from "./pages/services/SupportPage";
import CreateModelsPage from "./pages/services/CreateModelsPage";
import ConsaltingPage from "./pages/services/ConsaltingPage";
import ProjectSearchPage from "./pages/services/ProjectSearchPage";
import SecurityIntelectPage from "./pages/services/SecurityIntelectPage";
import PartnersPage from "./pages/PartnersPage";
import AboutUsPage from "./pages/AboutUsPage";
import ElectroDocOborotPage from "./pages/aboutUsLinks/ElectroDocOborotPage";
import PeopleWebAnswerPage from "./pages/aboutUsLinks/PeopleWebAnswerPage";
import CnapPage from "./pages/aboutUsLinks/CnapPage";
import SocialServicePage from "./pages/aboutUsLinks/SocialServicePage";
import LandResourcePage from "./pages/aboutUsLinks/LandResourcePage";
import PersonnelQuestionPage from "./pages/aboutUsLinks/PersonnelQuestionPage";
import ArchiveDepartmentPage from "./pages/aboutUsLinks/ArchiveDepartmentPage";
import RegisterTerCommunityPage from "./pages/aboutUsLinks/RegisterTerCommunity";
import ContactsHeadPage from "./pages/ContactsHeadPage";

const Notfound = () => (
  <div className="notFoundPage">
    <div className="notfoundBox">
      Нажаль, не вдалося знайти сторінку по вашому запиту!
      <br /> Повернутися на <Link to="/">Головну</Link>
    </div>
  </div>
);
function App() {
  return (
    <div className="AppMAIN">
      <Routes>
        <Route path="/notfound" element={<Notfound />} />
        <Route path="*" element={<Navigate to="/notfound" />} />

        <Route path="/" element={<HomePage />} />
        <Route path="/uk" element={<HomePage />} />
        <Route path="/ru" element={<HomePage />} />
        <Route path="/en" element={<HomePage />} />
        <Route path="/kai-documentooborot" element={<KdocumentPage />} />
        <Route
          path="/software-development"
          element={<SoftwareDevelopmentPage />}
        />
        <Route path="/support-and-implementation" element={<SupportPage />} />
        <Route
          path="/creation-of-digital-models"
          element={<CreateModelsPage />}
        />
        <Route
          path="/consulting-and-geoligical-support"
          element={<ConsaltingPage />}
        />
        <Route
          path="/design-and-survey-works"
          element={<ProjectSearchPage />}
        />
        <Route
          path="/protection-of-intellectual-ownership"
          element={<SecurityIntelectPage />}
        />
        <Route path="/partners" element={<PartnersPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/about-us-docflow" element={<ElectroDocOborotPage />} />
        <Route
          path="/about-us-civiltreatment"
          element={<PeopleWebAnswerPage />}
        />
        <Route path="/about-us-cnap" element={<CnapPage />} />
        <Route path="/about-us-socialservice" element={<SocialServicePage />} />
        <Route path="/about-us-landservice" element={<LandResourcePage />} />
        <Route
          path="/about-us-personnelquestion"
          element={<PersonnelQuestionPage />}
        />
        <Route
          path="/about-us-archivedepartment"
          element={<ArchiveDepartmentPage />}
        />
        <Route
          path="/about-us-regist-ter-commun"
          element={<RegisterTerCommunityPage />}
        />
        <Route path="/contacts" element={<ContactsHeadPage />} />
      </Routes>
    </div>
  );
}

export default App;
